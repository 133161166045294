<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <!-- <img src="{{ coreConfig.app.appLogoImage}}" alt="brand-logo" height="36" /> -->
         <img src="{{ coreConfig.layout.skin == 'dark' ? 'assets/images/logo/logo-dark.png' : 'assets/images/logo/logo.png' }}" alt="brand-logo" height="36" />

        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <div class="d-none d-xl-block">
      <ul class="nav navbar-nav">
        <!-- <li class="nav-item d-none d-lg-block">
          <a [routerLink]="['/my-account/profile']" class="nav-link nav-link-style btn" tooltipClass="fadeInAnimation" placement="bottom"
          ngbTooltip="Settings"  >
            <span [data-feather]="'settings'" class="ficon font-medium-5 feather"></span>
          </a>
        </li> -->
        <!-- <li class="nav-item d-none d-lg-block">
          <a  [routerLink]="['/my-account/profile']" class="nav-link nav-link-style btn" tooltipClass="fadeInAnimation" placement="bottom"
          ngbTooltip="Email">
            <span [data-feather]="'mail'" class="ficon font-medium-5 feather"></span>
          </a>
        </li> -->
        <!-- <li class="nav-item d-none d-lg-block">
          <a type="button" class="nav-link nav-link-style btn" tooltipClass="fadeInAnimation" placement="bottom"
          ngbTooltip="Logout" (click)="logout()">
            <span [data-feather]="'power'" class="ficon font-medium-5 feather"></span>
          </a>
        </li> -->
      </ul>
    </div>
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <img class='fl-img' [src]="'assets/images/flag-'+languageOptions[_translateService.currentLang].flag+'.png'">
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <img class='fl-img' [src]="'assets/images/flag-'+languageOptions[lang].flag+'.png'">
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Toggle skin -->
   
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <i class="ficon" data-feather="sun" ></i>
      </a>
    </li>
    <!-- <li><button type="button" class="btn btn-primary" (click)="logout()">Logout</button></li> -->
    
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- News -->
    <!-- <app-navbar-news></app-navbar-news> -->
    <!--/ News -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{currentUser | username}}</span>
            <span class="user-status">{{ currentUser.role }}</span>
          </div>
          <span class="avatar"
            ><img
              class="round"
              src="../../../../assets/images/avatars/10.png"
              alt="avatar"
              height="40"
              width="40" /><span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <div class="dropdown-divider"></div> -->
        <a ngbDropdownItem routerLink="/profile">
          <span [data-feather]="'user'" [class]="'mr-50'"></span> Profile
        </a>
        <a ngbDropdownItem (click)="logout()" >
          <span [data-feather]="'power'" [class]="'mr-50'"></span> Logout
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
