<li ngbDropdown class="nav-item dropdown-cart mr-25">
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown"
    ><span [data-feather]="'coffee'" [class]="'ficon'"></span
    ><span class="badge badge-pill badge-primary badge-up cart-item-count">{{ news.length }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">News</h4>
        <div class="badge badge-pill badge-light-primary">{{ news.length }} Items</div>
      </div>
    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <ng-container *ngIf="news.length">
      </ng-container>
    </li>
    <!--/ Cart content -->
  </ul>
</li>
