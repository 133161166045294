<section class="login-house" *ngIf="status !=1">
    <div class="container-fluid" style=" padding-left: 23%; ">
        <div class="col-md-8 cards">
            <div class="container my-auto py-5">
                <div class="row">
                    <div class="col-10 col-lg-9 col-xl-8 mx-auto">
                        <div class="m-t-30 card-box"
                            style="margin-bottom: 20px;background-clip: padding-box;-moz-border-radius: 5px;border-radius: 5px;-webkit-border-radius: 5px;padding: 20px;background-color: #ffffff;box-shadow: 0 8px 42px 0 rgba(0, 0, 0, 0.08);">
                            <div class="text-center">
                                <h4 class="text-uppercase font-bold m-b-0">{{ checkingCode ? 'Please wait!!' : status ==
                                    1 ? 'Success !!' : 'Error !!' }}</h4>
                            </div>
                            <div class="panel-body text-center">
                                <div *ngIf="checkingCode">
                                    <i class="fa fa-spinner fa-spin fa-3x"></i>
                                    <p class="text-muted font-13 m-t-20" style="color: #9a9da0">
                                        Please wait! While we processing!
                                    </p>
                                </div>

                                <div *ngIf="!checkingCode && status == 2">
                                    <i class="fa fa-times-circle fa-3x text-danger"></i>
                                    <p class="text-muted font-13 m-t-20" style="color: #9a9da0">
                                        Oops! Seems like no product found.
                                    </p>
                                </div>

                                <div *ngIf="!checkingCode && status == 1">
                                    <i class="fa fa-check-circle fa-3x text-success"></i>
                                    <p class="text-muted font-13 m-t-20" style="color: #9a9da0">
                                        Congratulations! .Your account is verified. Please login your account to
                                        continue further.
                                    </p>
                                    <div class="d-grid my-4">
                                        <button class="btn btn-primary" routerLink="/login">Login</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="page-content container p-4" *ngIf="status ==1">
    <div class="page-header text-blue-d2">
        <h1 class="page-title text-secondary-d1">
            Product Detail
            <small class="page-info">
                <i class="fa fa-angle-double-right text-80"></i>
                ID: {{productId}}
            </small>
        </h1>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-12">
                <div class="mt-4">
                    <div class="row text-600 text-white bgc-default-tp1 py-25">
                        <div class="d-none d-sm-block col-1">#</div>
                        <div class="col-9 col-sm-5">Name</div>
                        <div class="d-none d-sm-block col-4 col-sm-2">Detail</div>
                    </div>

                    <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                        <div class="row mb-2 mb-sm-0 py-25">
                            <div class="d-none d-sm-block col-1">1</div>
                            <div class="col-9 col-sm-5">Product Name</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.product_name}}</div>
                        </div>

                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">2</div>
                            <div class="col-9 col-sm-5">Product Description</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.product_description}}</div>
                        </div>

                        <div class="row mb-2 mb-sm-0 py-25">
                            <div class="d-none d-sm-block col-1">3</div>
                            <div class="col-9 col-sm-5">Ingredients</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.ingredients}}</div>
                        </div>

                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">4</div>
                            <div class="col-9 col-sm-5">Manufacturer</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.manufacturer}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">5</div>
                            <div class="col-9 col-sm-5">Manufacturer Date</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.manufactured_date}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">6</div>
                            <div class="col-9 col-sm-5">Expirey Date</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.expiry_date}}</div>
                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">7</div>
                            <div class="col-9 col-sm-5">Recylable</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.recyclable}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">8</div>
                            <div class="col-9 col-sm-5">Authorized Distributer</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.authorized_distributers}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">9</div>
                            <div class="col-9 col-sm-5">Intresting Recipes</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.interesting_recipes}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">10</div>
                            <div class="col-9 col-sm-5">About Organization</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.about_the_company}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">11</div>
                            <div class="col-9 col-sm-5">Contact Us</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.contact_us}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">12</div>
                            <div class="col-9 col-sm-5">Nutri Score</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.nutri_score}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">13</div>
                            <div class="col-9 col-sm-5">Health Warning</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.health_warning}}</div>

                        </div>
                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">14</div>
                            <div class="col-9 col-sm-5">Product Warning</div>
                            <div class="d-none d-sm-block col-2">{{productsDetails.product_warning}}</div>
                        </div>
                    </div>

                    <div class="row border-b-2 brc-default-l2"></div>

                    <hr />

                    <div>
                        <a href="#" class="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0"
                            (click)="click()">Product History</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>